<!--
    组件说明:
           propertys:    value(必须）搜索返回的参数-值
                         items(必须) 所有搜索条件配置
                         params(可选) 初始搜索条件
           events:       search(必须) 搜索点击事件
-->
<template>
    <div style="margin: 15px 0 5px 10px;">
        <el-form :inline="true" v-model="searchForm">
            <template v-for="item in items">
                <el-form-item v-if="item.type === Search_Type.Input">
                    <el-input
                            :placeholder="item.label"
                            v-model="searchForm[item.name]"
                            clearable>
                    </el-input>
                </el-form-item>
                <el-form-item v-show="false" v-if="item.type === Search_Type.Hidden">
                    <el-input
                            :placeholder="item.label"
                            v-model="searchForm[item.name]"
                            disabled
                            clearable>
                    </el-input>
                </el-form-item>
                <el-form-item v-else-if="item.type === Search_Type.Select">
                    <el-select  v-model="searchForm[item.name]" :placeholder="item.label">
                        <el-option v-for="(value,name,index) in item.props" :key="index" :label="value" :value="name"></el-option>
                    </el-select>
                </el-form-item>
                <el-date-picker v-else-if="item.type===Search_Type.Date||item.type===Search_Type.Datetime"
                        v-model="searchForm[item.name]"
                        align="right"
                        :type="item.type"
                        :value-format="item.type===Search_Type.Date?'yyyy-MM-dd':'yyyy-MM-dd HH-mm-ss'"
                        :placeholder="item.label"
                        :picker-options="PickerOptions.Base">
                </el-date-picker>
                <el-date-picker v-else-if="item.type===Search_Type.Daterange||item.type===Search_Type.Datetimerange"
                                v-model="searchForm[item.name]"
                                :type="item.type"
                                :value-format="item.type===Search_Type.Daterange?'yyyy-MM-dd':'yyyy-MM-dd HH-mm-ss'"
                                :picker-options="PickerOptions.Range"
                                range-separator="至"
                                :start-placeholder="item.label+ '(起点)'"
                                :end-placeholder="item.label+ '(终点)'"
                                align="right">
                </el-date-picker>
            </template>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            <el-form-item style="float: right">
                <slot></slot>
                <!--                <el-button type="primary" >新增</el-button>-->
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {Search_Type, PickerOptions} from '../../constants';
    export default {
        data(){
            return {
                // searchForm:{
                //
                // },
                // items:[
                //     {
                //         id: 1,
                //         label: '设备名称',
                //         name: 'equipName',
                //         type: 'input',
                //     },
                //     {
                //         id: 2,
                //         label: '设备型号',
                //         name: 'equipVersionName',
                //         type: 'input',
                //     },
                //     {
                //         id: 3,
                //         label: '设备状态',
                //         name: 'status',
                //         type: 'select',
                //         props: {
                //             0: '未注册',
                //             1: '已注册',
                //             9: '停用'
                //         }
                //     },
                //     {
                //         id: 4,
                //         label: '开始时间',
                //         name: 'startdate',
                //         type: 'datetime'
                //     }
                // ],
                Search_Type,
                PickerOptions
            }
        },
        props:{
            value: {
                type: Object,
                required: true
            },
            items:{
                type: Array,
                required: true
            },
            params:{
                type: Array,
                required: false,
            }
        },
        computed:{
            searchForm: {
                get(){
                    return this.value;
                },
                set(val){
                    this.$emit('input', val)
                }
            }
        },
        methods:{
            onSubmit(){
                this.$emit('search');
            },
            loadParams(){
                const searchParams = this.params;
                if(searchParams && searchParams.length > 0){
                    this.params.forEach(param=>this.searchForm[param.name]=param.value);
                    this.onSubmit();
                }
            }
        },
        watch:{
            params:{
                deep: true,
                handler: function (newVal, oldVal) {
                    this.loadParams();
                }
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                // 仅在整个视图都被渲染之后才会运行的代码
                // 增加该逻辑是因为第一次打开dialog,不会触发params的watch事件
                this.loadParams();
            })
        },
    }
</script>

<style scoped>

</style>